import React, {useRef} from "react";


const Search = ({setFilter}) => {
    const queryRef = useRef(null)

    function search(){
        setFilter(filter => {
            return {
                ...filter,
                name: queryRef.current.value
            }
        })
    }


    return (
        <div>
            <div className="input-group my-4" style={{maxWidth: '500px'}}>

                <input type="text" name="search" className="form-control" placeholder="Найти пользователя"
                       aria-label="Запрос" aria-describedby="button-addon2" ref={queryRef}/>
                <button className="btn btn-outline-primary" type="submit" onClick={search}>Поиск</button>
            </div>
        </div>
    )
}
export default Search
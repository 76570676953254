import Messages from './Messages'
import Send from './Send'
import {useParams} from "react-router-dom";
import {useState, useEffect, useContext} from "react";
import axios from "axios";
import {apiUrl} from "../../config";
import {getAuthCookie, create_socket} from "../../modules";
import {authContext} from "../Context";

const Index = () => {
    const chat_id = useParams().id
    const [chat, setChat] = useState({})
    const {User} = useContext(authContext)

    useEffect(()=> {
        console.log('render effect')
        axios.get(apiUrl+'/tickets/get-chat/' + chat_id, getAuthCookie())
            .then(resp => setChat(resp.data))

        const socket = create_socket('/chat')
        socket.on('connect', (data)=>{
            socket.emit('join_chat', {'ticket':chat_id})
        })
        socket.on('onClose', () => {
            setChat(chat => {
                return {
                    ...chat,
                    status:'closed'
                }
            })
        })
        socket.on('message', data => {
            console.log(data)
            setChat(chat => {
                return {
                    ...chat,
                    messages: [...chat.messages, data]
                }
            })
        })
        return () => {
            socket.disconnect()
        }
    }, [])

    const close_ticket = () => {
        let check = window.confirm('Вы уверены что хотите закрыть тикет?')
        if (!check) return

        axios.get(apiUrl+'/tickets/close-ticket/'+chat_id, getAuthCookie())
            .then(resp=> setChat(chat => {
                return {
                    ...chat,
                    status:'closed'
                }
            }))
    }

    return (
        <main className="align-content-center mx-auto" style={{maxWidth:'900px',maxHeight:'90vh'}}>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h2 className="h2">Тикет #{chat_id} от {chat.messages?.length && chat?.messages[0]?.username}</h2>
                {chat.chat_members?.includes(User.id) && chat.status !== 'closed' && <button className={'btn btn-outline-danger'} onClick={close_ticket}>Закрыть тикет</button>}
            </div>
            <div className="container" style={{position:'relative'}}>

                <ul className="list-unstyled" style={{overflowY:'scroll',maxHeight:'75vh'}} id="messages">
                   <Messages messages={chat.messages}/>
                </ul>
                {Object.keys(chat).length > 0 && <Send chat={chat} setChat={setChat}/>}
            </div>
        </main>
    )
}

export default Index;

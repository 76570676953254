import React, {useState, useEffect} from "react";
import axios from "axios";
import {useLocation} from "react-router-dom";
import {ReactComponent as X} from "../../icons/x-lg.svg";
import {getAuthCookie} from "../../modules";
import {apiUrl, statuses, statuses_buttons, statuses_styles} from "../../config";

const Cert = ({cert, setRows}) => {
    const state = useLocation().state


    function nextStatus(is_canceled=false) {
        let check = window.confirm(
            is_canceled ?
                `Вы уверены что хотите отменить заявку №${cert.id}?` :
                `Вы уверены что хотите изменить статус заявки №${cert.id}?`
        )

        if (!check) return

        axios.post(apiUrl + '/status/next', {id: cert.id,  type_id: state.id, type: state.type,'is_canceled':is_canceled},getAuthCookie())
            .then(resp => {
                setRows(rows => {
                    return [...rows].map(row => {
                        if (row.id===cert.id){
                            return {
                                ...row,
                                status:resp.data.status
                            }
                        }
                        return row
                    })
                })
            })
    }

    return (
        <tr className={'align-middle'}>
            <td>{cert.id}</td>
            <td>@{cert.username} ({cert.user_id})</td>
            <td>{cert.last_name}</td>
            <td>{cert.name}</td>
            <td>{cert.father_name}</td>
            <td>{cert.email}</td>
            <td>{cert.birthday}</td>
            <td>{cert.group_name}</td>
            {state.id==='2' && <td >{cert.date_start}-{cert.date_end}</td>}

            <td>{cert.count}</td>
            <td>{cert.create_at}</td>
            <td>{statuses[cert.status]}</td>
            <td>{cert.target}</td>
            <td>
                <button className={`btn btn-${statuses_styles[cert.status]}`} disabled={['closed','canceled'].includes(cert.status)}
                        onClick={() => {nextStatus()}}>
                    {statuses_buttons[cert.status]}
                </button>
            </td>
            <td><button className={'btn btn-outline-danger'} disabled={['closed','canceled'].includes(cert.status)} onClick={() => {nextStatus(true)}}>
                <X/>
            </button></td>
        </tr>
    )
}
export default Cert
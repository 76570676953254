

const Filter = ({filter, setFilter}) => {

    function filterHandle({target:{value, name, checked}}) {
        console.log(filter)
        if (name==='role') {
            let array = [...filter.roles]

            if (checked) {
                array.push(value)
            } else {
                array = array.filter(status => status !== value)
            }
            console.log(value, typeof value)
            setFilter({
                ...filter,
                roles: array
            })

        } else {
            setFilter({
                ...filter,
                [name]:value
            })
        }
    }

    return (
        <div className={'d-flex'}>
            <div className="form-check me-3">
                <input className="form-check-input" onChange={filterHandle} name={'role'} defaultChecked={filter.roles.includes('1')} type="checkbox" defaultValue={'1'} id={'1'}/>
                    <label className="form-check-label" htmlFor={'1'}>
                        Администраторы
                    </label>
            </div>
            <div className="form-check me-3">
                <input className="form-check-input" onChange={filterHandle} name={'role'} defaultChecked={filter.roles.includes('2')}  type="checkbox" defaultValue={'2'} id={'2'}/>
                <label className="form-check-label" htmlFor={'2'}>
                    Менеджеры
                </label>
            </div>

            <div className="form-check me-3">
                <input className="form-check-input" onChange={filterHandle} name={'role'} defaultChecked={filter.roles.includes('3')} type="checkbox" defaultValue={'3'} id={'3'}/>
                <label className="form-check-label" htmlFor={'3'}>
                    Сотрудники
                </label>
            </div>
            <div className="form-check me-3">
                <input className="form-check-input" onChange={filterHandle} name={'role'} defaultChecked={filter.roles.includes('NULL')} type="checkbox" defaultValue={'NULL'} id={'null'}/>
                <label className="form-check-label" htmlFor={'null'}>
                    Без ролей
                </label>
            </div>


        </div>
    )
}
export default Filter
import React, {useEffect} from 'react';
import {Navigate, useLocation} from "react-router-dom";
import Cert from "./Cert";
import {create_socket} from "../../modules";

const Body = ({certificates, filter, setRows}) => {
    const state = useLocation().state


    function sortHandle(x, y) {
        return filter.sort_by_new ? y.id - x.id : x.id - y.id
    }
    function filterHandler(filter, cert) {
        const fullname = `${cert.last_name} ${cert.name} ${cert.father_name}`
        if (!cert.id.toString().includes(filter.id)) return false
        if (!cert.group_name.toLowerCase().includes(filter.group_name.toLowerCase())) return false
        if (!fullname.includes(filter.author)) return false
        if (!filter.statuses.includes(cert.status)) return false

        return true
    }

    useEffect(() => {
        if (state === null) return
        const socket = create_socket(`/${state.type}`)

        socket.on('changeStatus', (data) => {
            setRows(rows => {
                return [...rows].map(row => {
                    if (row.id===data.id){
                        return {
                            ...row,
                            status:data.status
                        }
                    }
                    return row
                })
            })
        })
        socket.on('addCertificate', (data) => {

            setRows(rows => {
                return filter.sort_by_new ? [data, ...rows] : [ ...rows,data]
            })
        })
        return () => {
            socket.disconnect()
        }
    }, [])
    if (state === null) {
        return <Navigate to={'/'}/>
    }
    return (
        <div className=''>
            <h3>Справки {state.title}:</h3>
            <button className="btn btn-outline-dark my-3 px-5" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#certFilters" aria-controls="certFilters">Фильтры
            </button>
            <div className="table-responsive">

            <table className={'table table-hover'}>
            <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Телеграм</th>
                <th scope="col">Фамилия</th>
                <th scope="col">Имя</th>
                <th scope="col">Отчество</th>
                <th scope="col">Почта</th>
                <th scope="col">Дата рождения</th>
                <th scope="col">Группа</th>
                {state.id==='2' && <th scope="col">Период</th>}
                <th scope="col">Кол-во</th>
                <th scope="col">Дата запроса</th>
                <th scope="col">Статус</th>
                <th scope="col">Место требования</th>
                <th scope="col"></th>
                <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            {certificates?.sort(sortHandle)?.map(row => {
                if (filterHandler(filter, row)) {
                    return <Cert key={row.id} cert={row} setRows={setRows}/>
                }
            }

            )}
            </tbody>
        </table>
            </div>
            </div>
    )
}

export default Body;
import React, {useState, useEffect} from "react";
import axios from "axios";
import {apiUrl} from "../../config";
import {getAuthCookie} from "../../modules";
import User from "./User";
import Form from "./Form";
import Search from "./Search"
import Filter from "./Filter"
import AlertForm from "./AlertForm";

const Users = () => {
    const [roles, setRoles] = useState([])
    const [users, setUsers] = useState([])
    const [target, setTarget] = useState({})
    const [filter, setFilter] = useState({name: '', roles: ['1','2','3']})
    console.log('render users', target)
    useEffect(() => {
        axios.get(apiUrl + '/config/roles', getAuthCookie())
            .then(resp => setRoles(resp.data))
    }, [])

    useEffect(() => {

        const params = new URLSearchParams(filter).toString();
        axios.get(apiUrl + '/config/users?'+params, getAuthCookie())
            .then(resp => setUsers(resp.data))
    }, [filter])

    return (
        <div className="justify-content-between flex-wrap flex-md-nowrap  pt-3 pb-2 mb-3 border-bottom container">
            <h1 className="h2">Список пользователей:</h1>
            <Search setFilter={setFilter}/>
            <Filter filter={filter} setFilter={setFilter}/>
            {users.map((user) => <User key={user.id} user={user} setTarget={setTarget} roles={roles}/>)}
            <AlertForm target={target}/>
            <Form target={target} setTarget={setTarget} roles={roles} setUsers={setUsers}/>

        </div>
    )
}
export default Users;
import Picker from 'emoji-picker-react';
import {FilePicker} from 'react-file-picker'

import {useState, useContext} from "react";
import {useParams} from "react-router-dom";
import {authContext} from "../Context";

import {ReactComponent as Smile} from "../../icons/emoji-smile-fill.svg";
import {ReactComponent as Paperclip} from "../../icons/paperclip.svg";
import {ReactComponent as SendFill} from "../../icons/send-fill.svg";
import Uploader from "./Uploader";
import axios from "axios";
import {getAuthCookie} from "../../modules";
import {apiUrl} from "../../config";

const Send = ({chat, setChat}) => {
    const ticket_id = useParams().id
    const {User} = useContext(authContext)
    const [message, setMessage] = useState({
        text: '',
        files: []
    })
    const [pickerVisible, setPickerVisible] = useState(false)

    const onEmojiClick = (emojiObject, event) => {
        setMessage(msg => {
            return {
                text: msg.text + emojiObject.emoji,
                files: msg.files
            }
        })
    };

    const sendMessage = async () => {
        if (!message.text) return

        axios.post(apiUrl + '/tickets/add-message', {
            id: parseInt(ticket_id),
            text: message.text
        }, getAuthCookie())
            .then(resp => {
                console.log('message sended')
            })

        setMessage({
            text: '',
            files: []
        })
        console.log('send:', message)
    }

    function joinChat() {
        axios.get(apiUrl+'/tickets/join-chat/'+ticket_id, getAuthCookie())
            .then(resp => setChat(chat => {
                return {
                    ...chat,
                    chat_members: [...chat.chat_members, User.id]
                }
            }))
    }

    if (!chat.chat_members?.includes(User.id) && chat.status !== 'closed') {
        return (<div className={'fixed-bottom mb-2'}>
                <div className={'mx-auto'} style={{maxWidth: '900px'}}>
                    <button className={'btn btn-lg btn-primary p-3'} style={{width:'100%'}} onClick={joinChat}>Присоединиться к чату</button>
                </div>
            </div>
        )
    } else if (chat.status === 'closed') {
        return (<div className={'fixed-bottom mb-2'}>
                <div className={'mx-auto'} style={{maxWidth: '900px'}}>
                    <button className={'btn btn-lg btn-danger disabled p-3'} disabled={true} style={{width:'100%'}} onClick={joinChat}>Тикет был закрыт</button>
                </div>
            </div>
        )
    }

    return (<div className={'mb-2 fixed-bottom'}>
            <div className={'mx-auto'} style={{maxWidth: '900px'}}>

                {pickerVisible && <div style={{position: 'absolute', end: '1000px', bottom: '150px'}}><Picker
                    onEmojiClick={onEmojiClick}/></div>}

                <div
                    className="card-footer text-muted d-flex justify-content-start align-items-center px-3 border rounded-3"
                    style={{backgroundColor: '#fafafae7'}}>
                    <a className={`m-2 ${!pickerVisible && "text-muted"}`} href="#" onClick={() => {
                        setPickerVisible(state => !state)
                    }}> <Smile/></a>

                    <textarea className=" mx-1 form-control form-control-lg border rounded-3 m-3"
                              style={{borderColor: 'transparent'}}
                              id="exampleFormControlInput1"
                              rows={4}
                              placeholder="Написать сообщение" onChange={({target: {value}}) => {
                        setMessage(msg => {
                            return {text: value, files: msg.files}
                        })
                    }} value={message.text}/>

                    <a className="ms-3 text-muted" href="#" onClick={sendMessage}><SendFill/></a>
                </div>
            </div>
        </div>
    )
}
export default Send